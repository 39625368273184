<template>
  <v-app>
    <v-card width="98%" class="mx-auto mt-5 pa-5">
      <LocaleSwitcher />
      {{
        $t(
          "welcome.処方されたコンタクトレンズを、こちらのページからご注文いただけます。"
        )
      }}
      <v-card-title class="justify-center" v-if="clinic">
        {{ clinic.name }}
      </v-card-title>
      <v-img
        v-if="clinic"
        height="50"
        :src="clinic.logourl"
        aspect-ratio="1.7"
        contain
      ></v-img>
      <v-card-text>
        <div v-if="clinic">
          {{ $t("welcome.販売元") }}：<b>{{ clinic.saler_name }}</b>
          <br />
          {{ clinic.comment }}
          <br /><br />
          <ul style="list-style: none">
            <li>
              {{ $t("welcome.License_Number") }}:
              {{ clinic.sales_license }}
            </li>
            <li>
              <a :href="clinic.sct_url" target="_brank">{{
                $t("welcome.特定商取引法に基づく表記")
              }}</a>
            </li>
            <li>{{ $t("welcome.住所") }}: {{ clinic.saler_address }}</li>
            <li>
              {{ $t("welcome.ホームページ") }}:
              <a :href="clinic.saler_weburl" target="_brank">{{
                clinic.saler_weburl
              }}</a>
            </li>

            <li>
              {{ $t("welcome.ご注文品の配送状況や破損品交換のお問い合わせ") }}
            </li>
            <li>{{ $t("welcome.営業日") }}: {{ clinic.saler_date }}</li>
            <li>{{ $t("welcome.休業日") }}: {{ clinic.saler_holiday }}</li>
            <li>{{ $t("welcome.営業時間") }}: {{ clinic.saler_time }}</li>
            <li>{{ $t("welcome.電話番号") }}: {{ clinic.saler_call }}</li>
            <li>{{ $t("welcome.E-mail") }}: {{ clinic.saler_email }}</li>
          </ul>
        </div>
      </v-card-text>
      <v-card-text>
        <p>
          {{ $t("welcome.welcome_lead") }}<br /><br />
          {{ $t("welcome.welcome_sgin_regist") }}。
        </p>
        <div>
          <v-btn
            outlined
            color="indigo"
            elevation="2"
            large
            v-on:click="regist()"
            class="ml-10"
          >
            {{ $t("sginIn.新規登録") }}
          </v-btn>

          <v-btn
            outlined
            color="green"
            elevation="2"
            large
            v-on:click="login()"
            class="ml-5"
          >
            {{ $t("sginIn.ログイン") }}
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-app>
</template>

<script>
import clinic from "@/mixins/clinic";
import LocaleSwitcher from "@/components/LocaleSwitcher";
export default {
  name: "Welcome",
  mixins: [clinic],
  components: { LocaleSwitcher },
  created() {
    this.clinicFetchById();
  },
  computed: {
    id() {
      return this.queryToStringL(this.$route.params.id);
    },
    clinic() {
      if (this.id === "") {
        return null;
      } else {
        return this.$store.state.clinicModule.data[this.id];
      }
    },
  },
  methods: {
    queryToStringL: function (value) {
      if (typeof value === "string" && value) {
        return value;
      }
      throw new Error("Invalid query value");
    },
    regist: function () {
      this.$router.push({ name: "signup", params: { id: this.id } });
    },
    login: function () {
      this.$router.push({ name: "login", params: { id: this.id } });
    },
    clinicFetchById: async function () {
      try {
        await this.$store.dispatch("clinicModule/fetchById", this.id);
      } catch (e) {
        this.error = true;
        this.$router.push({
          name: "error",
          params: { message: "表示できません。" },
        });
      } finally {
        this.loading = false;
      }
      this.clinic_model.id = this.id;
      this.clinic_model.name =
        this.$store.state.clinicModule.data[this.id].name;
      this.clinic_model.address =
        this.$store.state.clinicModule.data[this.id].address;
      this.clinic_model.postcode =
        this.$store.state.clinicModule.data[this.id].postcode;
      this.clinic_model.phonenumber =
        this.$store.state.clinicModule.data[this.id].phonenumber;
      this.clinic_model.faxnumber =
        this.$store.state.clinicModule.data[this.id].faxnumber;
      this.clinic_model.status =
        this.$store.state.clinicModule.data[this.id].status;
      this.clinic_model.makers =
        this.$store.state.clinicModule.data[this.id].makers;
      this.clinic_model.logourl =
        this.$store.state.clinicModule.data[this.id].logourl;
      this.clinic_model.weburl =
        this.$store.state.clinicModule.data[this.id].weburl;
      await this.$store.commit("makerModule/RESET_VUEX_EASY_FIRESTORE_STATE");
    },
  },
};
</script>
